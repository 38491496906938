import styled from '@emotion/styled';

const DEFAULT_SPINNER_SIZE = 50;

const SpinnerContainer = styled.div<{ size: number; color?: string }>`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
        position: relative;
        width: ${props => `${props.size}px`};
        height: ${props => `${props.size}px`};
    }

    .loader:before,
    .loader:after {
        content: '';
        border-radius: 50%;
        position: absolute;
        inset: 0;
        box-shadow: 0 0 0px 2px #d7d9de inset;
    }
    .loader:after {
        box-shadow: 0 2px 0 ${props => props?.color ?? 'var(--spinner-color)'} inset;
        animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const Spinner = ({
    size = DEFAULT_SPINNER_SIZE,
    color,
    className,
}: {
    size?: number;
    color?: string;
    className?: string;
}) => (
    <SpinnerContainer size={size} color={color} className={className}>
        <span className="loader" />
    </SpinnerContainer>
);

export default Spinner;
