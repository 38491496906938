import useRouterQuery from '@/hooks/useRouterQuery';
import OptionClickableNode from './optionClickableNode';
import StyledTooltip from '../utils/styledTooltip';

const CPG_LINKS = [
    {
        href: 'one-brain',
        title: `One Brain`,
        subText: 'Talk with OneBrain for insights into your products',
    },
    {
        href: 'data-upload',
        title: `Data Upload`,
        subText: 'Upload your Data Sets to achieve the better outcomes',
    },
    // {
    //     href: 'entitiy-resolution',
    //     title: `Entity Resolution`,
    //     subText: 'View key performance results and inspect the model',
    // },
    // {
    //     href: 'model-output',
    //     title: `Model Outputs`,
    //     subText: 'TBD',
    // },
    // {
    //     href: 'use-cases',
    //     title: `Use Cases`,
    //     subText: 'Explore commercial use cases of OneBrain',
    // },
];
export const CPGComponent = () => {
    const { router } = useRouterQuery();
    return (
        <div className="flex justify-center items-center gap-3 h-dvh">
            {CPG_LINKS.map(item => (
                <StyledTooltip key={item.title} label={item.subText}>
                    <div>
                        <OptionClickableNode
                            data={item.title}
                            onClick={() => router.push(item.href)}
                            selectedNode={item.title}
                        />
                    </div>
                </StyledTooltip>
            ))}
        </div>
    );
};
