import { memo, useState } from 'react';
import { DatasetType } from '@/hooks/usePipelineProgressStatus';
import { BareCard } from 'andromeda/Card';
import '@mantine/code-highlight/styles.css';
import Text from 'andromeda/text';
import classNames from 'classnames';

const DatasetTypeMap = {
    products: 'Products',
    accounts: 'Accounts',
    transactions: 'Transactions',
    account_id_mapping: 'Account ID Mapping',
    clickstream_orders: 'Clickstream Orders',
};

interface UploadNodeProps {
    data: string;
    onClick: (datasetType: DatasetType) => void;
    selectedNode: string;
}

const OptionClickableNode = ({ data, onClick: select, selectedNode }: UploadNodeProps) => {
    const [uploadModel, setUploadModel] = useState(false);

    return (
        <BareCard
            className={classNames(
                'flex flex-col gap-2 h-fit rounded hover:shadow-md hover:cursor-pointer hover:shadow-primary-blue',
                {
                    'border border-primary-blue': data === selectedNode,
                },
            )}
            onClick={() => {
                setUploadModel(true);
                select(data as any);
            }}
            style={{
                background: 'var(--nodes-gradient)',
            }}
        >
            <div className="flex flex-row justify-between">
                <div className="flex">
                    <Text type="20Reg" className="!text-navy-solid-50 capitalize">
                        {DatasetTypeMap?.[data] ?? data.replace(/_/g, ' ')}
                    </Text>
                </div>
            </div>
        </BareCard>
    );
};

export default memo(OptionClickableNode);
