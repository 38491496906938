import Head from 'next/head';
import { useEffect } from 'react';
import ReactHeap from 'utils/heap';
import cx from 'classnames';
import { useGlobalContext } from '@/contexts/GlobalContext';
import isMobile from 'ismobilejs';
import Login from '../login';
import { HEAP_ID } from '../../buildConfig/processEnv';
import { useAppContext } from '../../contexts/appContext';
import AppLoading from '../appLoading';
import { CommonProps } from './types';
import ResponsiveNavBar from './nav/ResponsiveNavBar';
import MobileNavButton from './nav/MobileNavButton';
import useColorScheme from '../../hooks/useColorScheme';
import useHrefAccess from '../navBar/useHrefAccess';

interface Props extends CommonProps {
    title: string;
    legacy?: boolean;
    style?: React.CSSProperties;
    hideNavBar?: boolean;
}

export default function ResponsiveLayout({
    children,
    title,
    className = '',
    legacy = false,
    style,
    hideNavBar,
}: Props) {
    const { rawUser, user, fetchingToken } = useAppContext();
    const { isMobileNavOpen } = useGlobalContext(state => state);
    const { colorScheme } = useColorScheme();
    useHrefAccess();

    useEffect(() => {
        if (HEAP_ID) {
            ReactHeap.initialize(HEAP_ID);
        }
    }, []);

    if (fetchingToken) {
        return <AppLoading>Authenticating user</AppLoading>;
    }

    if (typeof window !== 'undefined' && window?.location?.href?.includes('carlsberg-sales')) {
        return (
            <div
                className={cx(className, `p-4 h-screen overflow-hidden grow relative z-[100]`, {
                    // Former func
                    '!ml-0 max-w-[100vw]': isMobile().phone,
                    // Support new nav functionality
                    'overflow-hidden': isMobileNavOpen,
                    'ml-[69px]': !hideNavBar,
                })}
                style={style}
            >
                {children}
            </div>
        );
    }

    if (!user || !rawUser || (!user.token && !user.siberiaToken) || !user.isLoggedIn) {
        return <Login />;
    }

    if (!user?.groups && !!user?.siberiaToken && !rawUser?.groups) {
        return <AppLoading>Loading user data</AppLoading>;
    }

    return (
        <div
            className={cx({
                dark: colorScheme === 'dark',
            })}
        >
            <Head>
                <title>{title}</title>
                <link rel="shortcut icon" href="/images/favicon.png" />
            </Head>
            {!hideNavBar && (
                <>
                    <MobileNavButton />
                    <div
                        className={cx(
                            'sp-nav-wrapper relative',
                            'fixed z-[99999] top-0 left-0 h-full transition-all',
                            'duration-500 ease-in-out max-md:-translate-x-full',
                            {
                                '!translate-x-0 w-screen': isMobileNavOpen,
                            },
                        )}
                    >
                        <ResponsiveNavBar />
                    </div>
                </>
            )}
            {!legacy ? (
                <div
                    className={cx(
                        'md:px-12 lg:px-20 pb-20 relative max-w-full',
                        {
                            'fixed left-0 top-0 h-full w-full overflow-hidden': isMobileNavOpen,
                            'md:ml-[68px]': !hideNavBar,
                        },
                        className,
                    )}
                    style={style}
                >
                    {children}
                </div>
            ) : (
                // "legacy" layout wrapper, compat with views that will be upgraded
                <div
                    className={cx(className, `p-4 h-screen overflow-hidden grow relative z-[100]`, {
                        // Former func
                        '!ml-0 max-w-[100vw]': isMobile().phone,
                        // Support new nav functionality
                        'overflow-hidden': isMobileNavOpen,
                        'ml-[69px]': !hideNavBar,
                    })}
                    style={style}
                >
                    {children}
                </div>
            )}

            {/* <UserOverrideDefaultBanner /> */}
        </div>
    );
}
